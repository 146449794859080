<template>
  <ul class="menu-nav">
    <li class="menu-section">
      <h4 class="menu-text">Yönetim Paneli</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>
    <router-link
      exact
      :to="{ name: 'lead-reports' }"
      v-slot="{ href, navigate, isActive, isExactActive }"
      custom
    >
      <li
        aria-haspopup="true"
        v-permission="{
          permissions: ['leads-read'],
        }"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-paper" title="Lead Yönetimi"></i>
          <span class="menu-text">Lead Raporları</span>
        </a>
      </li>
    </router-link>
    <router-link
      exact
      :to="{ name: 'lead-list' }"
      v-slot="{ href, navigate, isActive, isExactActive }"
      custom
    >
      <li
        aria-haspopup="true"
        v-permission="{
          permissions: ['leads-read'],
        }"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-expand" title="Lead Yönetimi"></i>
          <span class="menu-text">Lead Yönetimi</span>
        </a>
      </li>
    </router-link>
    <router-link
      :to="{ name: 'lead-match-list' }"
      v-slot="{ href, navigate, isActive, isExactActive }"
      custom
    >
      <li
        aria-haspopup="true"
        v-permission="{
          permissions: ['leads-read'],
        }"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i
            class="menu-icon flaticon-users-1"
            title="Kullanıcı - Lead Eşleşmesi"
          ></i>
          <span class="menu-text">Kullanıcı - Lead Eşleşmesi</span>
        </a>
      </li>
    </router-link>
    <!--
    <router-link
      :to="{ name: 'user-list' }"
      v-slot="{ href, navigate, isActive, isExactActive }"
      custom
    >
      <li
        v-if="!isAgent"
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        v-permission="{
          permissions: ['users-read'],
        }"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-user" title="Kullanıcı Yönetimi"></i>
          <span class="menu-text">Kullanıcı Yönetimi</span>
        </a>
      </li>
    </router-link>
    -->
    <router-link
      :to="{ name: 'student-list' }"
      v-slot="{ href, navigate, isActive, isExactActive }"
      custom
    >
      <li
        v-if="!isAgent"
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-group" title="Öğrenci Yönetimi"></i>
          <span class="menu-text">Öğrenci Yönetimi</span>
        </a>
      </li>
    </router-link>

    <router-link
      :to="{ name: 'agent-management' }"
      v-slot="{ href, navigate, isActive, isExactActive }"
      custom
    >
      <li
        v-if="!isAgent"
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-users-1" title="Öğrenci Yönetimi"></i>
          <span class="menu-text">Agent Yönetimi</span>
        </a>
      </li>
    </router-link>

    <li class="menu-section">
      <h4 class="menu-text">Tanımlar</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>

    <router-link
      :to="{ name: 'def-channels' }"
      v-slot="{ href, navigate, isActive, isExactActive }"
      custom
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        v-permission="{
          permissions: ['channels-read'],
        }"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-map" title="Kanallar"></i>
          <span class="menu-text">Kanallar</span>
        </a>
      </li>
    </router-link>
    <router-link
      :to="{ name: 'def-campaigns' }"
      v-slot="{ href, navigate, isActive, isExactActive }"
      custom
    >
      <li
        aria-haspopup="true"
        v-permission="{
          permissions: ['campaigns-read'],
        }"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i
            class="menu-icon flaticon2-delivery-package"
            title="Kampanyalar"
          ></i>
          <span class="menu-text">Kampanyalar</span>
        </a>
      </li>
    </router-link>
    <router-link
      :to="{ name: 'def-trainings' }"
      v-slot="{ href, navigate, isActive, isExactActive }"
      custom
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        v-permission="{
          permissions: ['educations-read'],
        }"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-list" title="Eğitimler"></i>
          <span class="menu-text">Eğitimler</span>
        </a>
      </li>
    </router-link>
    <router-link
      :to="{ name: 'def-packages' }"
      v-slot="{ href, navigate, isActive, isExactActive }"
      custom
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        v-permission="{
          permissions: ['productions-read'],
        }"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-layers" title="Ürünler"></i>
          <span class="menu-text">Ürünler</span>
        </a>
      </li>
    </router-link>

    <router-link
      :to="{ name: 'def-message-templates-list' }"
      v-slot="{ href, navigate, isActive, isExactActive }"
      custom
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        v-permission="{
          permissions: ['message-templates-read'],
        }"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-comment" title="Mesaj Şablonları"></i>
          <span class="menu-text">Mesaj Şablonları</span>
        </a>
      </li>
    </router-link>
    <router-link
      :to="{ name: 'def-interest-rates' }"
      v-slot="{ href, navigate, isActive, isExactActive }"
      custom
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        v-permission="{
          permissions: ['interest-rates-read'],
        }"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i
            class="menu-icon flaticon2-percentage"
            title="Mesaj Şablonları"
          ></i>
          <span class="menu-text">Vade Farkı</span>
        </a>
      </li>
    </router-link>
    <router-link
      :to="{ name: 'def-sale-periods' }"
      v-slot="{ href, navigate, isActive, isExactActive }"
      custom
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-graph" title="Satış Dönemi"></i>
          <span class="menu-text">Satış Dönemleri</span>
        </a>
      </li>
    </router-link>

    <router-link
      :to="{ name: 'def-registration-periods' }"
      v-slot="{ href, navigate, isActive, isExactActive }"
      custom
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-list" title="Kayıt Dönemi"></i>
          <span class="menu-text">Kayıt Dönemleri</span>
        </a>
      </li>
    </router-link>
    <router-link
      :to="{ name: 'def-sm-classes' }"
      v-slot="{ href, navigate, isActive, isExactActive }"
      custom
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-presentation-1" title="Sınıflar"></i>
          <span class="menu-text">Sınıflar</span>
        </a>
      </li>
    </router-link>

    <li class="menu-section">
      <h4 class="menu-text">Uygulamalar</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>

    <router-link
      :to="{ name: 'reminder-list' }"
      v-slot="{ href, navigate, isActive, isExactActive }"
      custom
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-calendar" title="Hatırlatıcılar"></i>
          <span class="menu-text">Hatırlatıcılar</span>
        </a>
      </li>
    </router-link>
    <router-link
      :to="{ name: 'notification-list' }"
      v-slot="{ href, navigate, isActive, isExactActive }"
      custom
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-notification" title="Bildirimler"></i>
          <span class="menu-text">Bildirimler</span>
        </a>
      </li>
    </router-link>
  </ul>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      currentUserRoles: [],
    };
  },

  async created() {
    try {
      this.currentUserRoles = await this.getCurrentUserRoles;
    } catch (error) {
      throw new Error(error);
    }
  },
  name: "AdminMenu",
  computed: {
    ...mapGetters(["getCurrentUserRoles"]),
    isAgent() {
      return (
        this.currentUserRoles.length === 1 &&
        this.currentUserRoles.includes("agent")
      );
    },
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
  },
};
</script>

<style></style>
