var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"menu-nav"},[_vm._m(0),_c('router-link',{attrs:{"exact":"","to":{ name: 'lead-reports' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{directives:[{name:"permission",rawName:"v-permission",value:({
        permissions: ['leads-read'],
      }),expression:"{\n        permissions: ['leads-read'],\n      }"}],staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-paper",attrs:{"title":"Lead Yönetimi"}}),_c('span',{staticClass:"menu-text"},[_vm._v("Lead Raporları")])])])]}}])}),_c('router-link',{attrs:{"exact":"","to":{ name: 'lead-list' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{directives:[{name:"permission",rawName:"v-permission",value:({
        permissions: ['leads-read'],
      }),expression:"{\n        permissions: ['leads-read'],\n      }"}],staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-expand",attrs:{"title":"Lead Yönetimi"}}),_c('span',{staticClass:"menu-text"},[_vm._v("Lead Yönetimi")])])])]}}])}),_c('router-link',{attrs:{"to":{ name: 'lead-match-list' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{directives:[{name:"permission",rawName:"v-permission",value:({
        permissions: ['leads-read'],
      }),expression:"{\n        permissions: ['leads-read'],\n      }"}],staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-users-1",attrs:{"title":"Kullanıcı - Lead Eşleşmesi"}}),_c('span',{staticClass:"menu-text"},[_vm._v("Kullanıcı - Lead Eşleşmesi")])])])]}}])}),_c('router-link',{attrs:{"to":{ name: 'student-list' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [(!_vm.isAgent)?_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-group",attrs:{"title":"Öğrenci Yönetimi"}}),_c('span',{staticClass:"menu-text"},[_vm._v("Öğrenci Yönetimi")])])]):_vm._e()]}}])}),_c('router-link',{attrs:{"to":{ name: 'agent-management' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [(!_vm.isAgent)?_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-users-1",attrs:{"title":"Öğrenci Yönetimi"}}),_c('span',{staticClass:"menu-text"},[_vm._v("Agent Yönetimi")])])]):_vm._e()]}}])}),_vm._m(1),_c('router-link',{attrs:{"to":{ name: 'def-channels' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{directives:[{name:"permission",rawName:"v-permission",value:({
        permissions: ['channels-read'],
      }),expression:"{\n        permissions: ['channels-read'],\n      }"}],staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-map",attrs:{"title":"Kanallar"}}),_c('span',{staticClass:"menu-text"},[_vm._v("Kanallar")])])])]}}])}),_c('router-link',{attrs:{"to":{ name: 'def-campaigns' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{directives:[{name:"permission",rawName:"v-permission",value:({
        permissions: ['campaigns-read'],
      }),expression:"{\n        permissions: ['campaigns-read'],\n      }"}],staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-delivery-package",attrs:{"title":"Kampanyalar"}}),_c('span',{staticClass:"menu-text"},[_vm._v("Kampanyalar")])])])]}}])}),_c('router-link',{attrs:{"to":{ name: 'def-trainings' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{directives:[{name:"permission",rawName:"v-permission",value:({
        permissions: ['educations-read'],
      }),expression:"{\n        permissions: ['educations-read'],\n      }"}],staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-list",attrs:{"title":"Eğitimler"}}),_c('span',{staticClass:"menu-text"},[_vm._v("Eğitimler")])])])]}}])}),_c('router-link',{attrs:{"to":{ name: 'def-packages' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{directives:[{name:"permission",rawName:"v-permission",value:({
        permissions: ['productions-read'],
      }),expression:"{\n        permissions: ['productions-read'],\n      }"}],staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-layers",attrs:{"title":"Ürünler"}}),_c('span',{staticClass:"menu-text"},[_vm._v("Ürünler")])])])]}}])}),_c('router-link',{attrs:{"to":{ name: 'def-message-templates-list' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{directives:[{name:"permission",rawName:"v-permission",value:({
        permissions: ['message-templates-read'],
      }),expression:"{\n        permissions: ['message-templates-read'],\n      }"}],staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-comment",attrs:{"title":"Mesaj Şablonları"}}),_c('span',{staticClass:"menu-text"},[_vm._v("Mesaj Şablonları")])])])]}}])}),_c('router-link',{attrs:{"to":{ name: 'def-interest-rates' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{directives:[{name:"permission",rawName:"v-permission",value:({
        permissions: ['interest-rates-read'],
      }),expression:"{\n        permissions: ['interest-rates-read'],\n      }"}],staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-percentage",attrs:{"title":"Mesaj Şablonları"}}),_c('span',{staticClass:"menu-text"},[_vm._v("Vade Farkı")])])])]}}])}),_c('router-link',{attrs:{"to":{ name: 'def-sale-periods' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-graph",attrs:{"title":"Satış Dönemi"}}),_c('span',{staticClass:"menu-text"},[_vm._v("Satış Dönemleri")])])])]}}])}),_c('router-link',{attrs:{"to":{ name: 'def-registration-periods' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-list",attrs:{"title":"Kayıt Dönemi"}}),_c('span',{staticClass:"menu-text"},[_vm._v("Kayıt Dönemleri")])])])]}}])}),_c('router-link',{attrs:{"to":{ name: 'def-sm-classes' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-presentation-1",attrs:{"title":"Sınıflar"}}),_c('span',{staticClass:"menu-text"},[_vm._v("Sınıflar")])])])]}}])}),_vm._m(2),_c('router-link',{attrs:{"to":{ name: 'reminder-list' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-calendar",attrs:{"title":"Hatırlatıcılar"}}),_c('span',{staticClass:"menu-text"},[_vm._v("Hatırlatıcılar")])])])]}}])}),_c('router-link',{attrs:{"to":{ name: 'notification-list' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-notification",attrs:{"title":"Bildirimler"}}),_c('span',{staticClass:"menu-text"},[_vm._v("Bildirimler")])])])]}}])})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text"},[_vm._v("Yönetim Paneli")]),_c('i',{staticClass:"menu-icon flaticon-more-v2"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text"},[_vm._v("Tanımlar")]),_c('i',{staticClass:"menu-icon flaticon-more-v2"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text"},[_vm._v("Uygulamalar")]),_c('i',{staticClass:"menu-icon flaticon-more-v2"})])
}]

export { render, staticRenderFns }