<template>
  <ul class="menu-nav">
    <router-link
      :to="{ name: 'EditProfile' }"
      v-slot="{ href, navigate, isActive, isExactActive }"
      custom
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fa fas flaticon2-writing"></i>
          <span class="menu-text">Profilim</span>
        </a>
      </li>
    </router-link>
    <router-link
      :to="{ name: 'user-offers-list' }"
      v-slot="{ href, navigate, isActive, isExactActive }"
      custom
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-document"></i>
          <span class="menu-text">Onay Bekleyen İşlemlerim</span>
        </a>
      </li>
    </router-link>

    <router-link
      :to="{ name: 'user-payments-list' }"
      v-slot="{ href, navigate, isActive, isExactActive }"
      custom
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fa fas fa-credit-card"></i>
          <span class="menu-text">Ödemelerim</span>
        </a>
      </li>
    </router-link>

    <router-link
      :to="{ name: 'user-documents-list' }"
      v-slot="{ href, navigate, isActive, isExactActive }"
      custom
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-folder"></i>
          <span class="menu-text">Belgelerim</span>
        </a>
      </li>
    </router-link>
  </ul>
</template>

<script>
export default {};
</script>

<style></style>
