<template>
  <fragment>
    <AdminMenu v-if="currentMenu === 'admin'" />
    <UserMenu v-else-if="currentMenu === 'user'" />
  </fragment>
</template>

<script>
import AdminMenu from "./menus/AdminMenu.vue";
import UserMenu from "./menus/UserMenu.vue";
export default {
  name: "KTMenu",
  computed: {
    currentMenu() {
      return this.$route.path.substring(1).split("/")[0];
    },
  },
  components: { AdminMenu, UserMenu },
};
</script>
